import { useSearchParams } from 'react-router-dom';
import ScrollToTop from '../../assets/hooks/scrollToTop';
// import HeadBanner from '../Main/HeadBanner/HeadBanner';
import './AboutUs.css';
import Departments from './Departments/Departments';
import Global from './Global/Global';
import HeaderBannerAboutUs from './HeaderBannerAboutUs/HeaderBannerAboutUs';
import OurPartners from './OurPartners/OurPartners';
import OurStorySteps from './OurStorySteps/OurStorySteps';
import SuccessInNumbers from './SuccessInNumbers/SuccessInNumbers';
import { useEffect } from 'react';
import { VALIDE_LANG } from '../../assets/utils/constants';

function AboutUs({ language, setLanguage }) {
    const [searchParams, setSearchParams] = useSearchParams();
    const lang = searchParams.get("lang");

    useEffect(() => {
        if (lang && VALIDE_LANG.includes(lang)) {
          setLanguage(lang);
          localStorage.setItem("language", lang);
        }
        else {
          setSearchParams(params => {
            params.set("lang", language);
            return params;
          });
          localStorage.setItem("language", language);
        }
      }, [])

    return (
        <div className='about-us'>
            <ScrollToTop />
            <div className='about-us__box'>
                {/* <HeadBanner/> */}
                <HeaderBannerAboutUs />
                <SuccessInNumbers />
                <OurStorySteps />
                <Global />
                <Departments />
                <OurPartners />
            </div>
        </div>
    );
}

export default AboutUs